import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SocialLinks } from "../src/components/SocialLinks/sociallinks";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 className="Intro__header">
Welcome
    </h1>

    <div className="Intro__subheader">
      <h4>
This is Fynn Faber's webpage and research blog.
      </h4>
      <SocialLinks mdxType="SocialLinks" />
    </div> 

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      